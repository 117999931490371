import { LngLatBounds } from "maplibre-gl";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useMap } from "../hooks/map";
import { useRoutes } from "../hooks/routes";
import { Panel, Panels } from "./Panel";

const SMALL_SCREEN_WIDTH = 768;

export function Legend() {
  const map = useMap();
  const routeFeatures = useRoutes();
  const [visiblePanelIndex, setVisiblePanelIndex] = useState(0);
  const [hideLegend, setHideLegend] = useState(false);

  const setLegendVisibility = (map: maplibregl.Map) => {
    const canvas = map.getCanvas();
    const pixelRatio = map.getPixelRatio();
    setHideLegend(canvas.width / pixelRatio < SMALL_SCREEN_WIDTH);
  };

  useEffect(() => {
    if (!map) return;

    setLegendVisibility(map);

    map.on("resize", () => {
      setLegendVisibility(map);
    });

    map.on("moveend", () => {
      if (!map) return;

      // Set which panel is active based on where the
      // center of the map is relative to an imaginary border
      // between Linköping and Norrköping.
      const center = map.getCenter();
      const a = { lng: 15.6985, lat: 58.6569 };
      const b = { lng: 16.3044, lat: 58.2907 };

      const isLeft =
        (b.lat - a.lat) * (center.lng - a.lng) >
        (b.lng - a.lng) * (center.lat - a.lat);
      setVisiblePanelIndex(isLeft ? 0 : 1);
    });
  }, [map]);

  const getPadding = () => {
    if (!map) return;

    const canvas = map.getCanvas();
    const paddingRatio = canvas.width < SMALL_SCREEN_WIDTH ? 0.01 : 0.1;
    const pixelRatio = map.getPixelRatio();
    const horizPadding = (canvas.width * paddingRatio) / pixelRatio;
    const vertPadding = (canvas.height * paddingRatio) / pixelRatio;

    return {
      bottom: vertPadding,
      left:
        horizPadding + canvas.width / pixelRatio < SMALL_SCREEN_WIDTH ? 0 : 270,
      right: horizPadding,
      top: vertPadding,
    };
  };

  const onLegendEntryClick = (feature: any) => {
    if (!map) return;

    const bounds = feature.geometry.coordinates.reduce(
      (a: LngLatBounds, coordinate: [number, number]) => a.extend(coordinate),
      new LngLatBounds()
    );

    map.fitBounds(bounds, {
      padding: getPadding(),

      maxDuration: 1500,
    });

    setLegendVisibility(map);
  };

  const getRoutesFor = (area: string) =>
    routeFeatures
      ?.filter((f) => f.properties.regions?.includes(area))
      .sort((f1, f2) => {
        var collator = new Intl.Collator([], { numeric: true });
        return collator.compare(
          f1.properties.route_short_name,
          f2.properties.route_short_name
        );
      })
      .map((f, i) => (
        <LegendEntry key={i} onClick={() => onLegendEntryClick(f)}>
          <Badge
            backgroundColor={f.properties.stroke}
            color={f.properties["text-color"]}
          >
            {f.properties.route_short_name}
          </Badge>
          <From>Från: {f.properties.from_area}</From>
          <To>Till: {f.properties.to_area}</To>
        </LegendEntry>
      ));

  return (
    <LegendContainer>
      <Panels
        visiblePanelIndex={visiblePanelIndex}
        onVisiblePanelIndexChanged={setVisiblePanelIndex}
        hideContent={hideLegend}
        onExpand={() => setHideLegend(!hideLegend)}
      >
        <Panel header="Linjer i Linköping">
          <LegendEntries hidden={hideLegend}>
            {getRoutesFor("linkoping")}
          </LegendEntries>
        </Panel>

        <Panel header="Linjer i Norrköping">
          <LegendEntries hidden={hideLegend}>
            {getRoutesFor("norrkoping")}
          </LegendEntries>
        </Panel>
      </Panels>
    </LegendContainer>
  );
}

const LegendContainer = styled.div`
  width: auto;
  background-color: rgba(63, 64, 67, 0.98);
  padding: 8px 8px 8px 16px;

  @media screen and (max-width: 220px) {
    margin-left: 0;
    padding-left: 4px;
  }
`;

const LegendEntries = styled.div``;

const LegendEntry = styled.div`
  display: grid;
  grid-template-columns: auto 2fr;
  grid-template-rows: auto auto;
  border-bottom: 1px solid white;
  :last-child {
    border-bottom: none;
  }
  :hover {
    cursor: pointer;
  }
`;

interface BadgeProps {
  backgroundColor?: string;
  color?: string;
}

const Badge = styled.div<BadgeProps>`
  grid-row: 1 / 3;
  grid-column: 1;
  font-family: "BitterPro";
  width: 40px;
  height: 25px;
  background-color: ${(p) => p.backgroundColor ?? "red"};
  color: ${(p) => p.color ?? "black"};
  border-radius: 5px;

  text-align: center;
  padding: auto;
  margin: 6px 6px 6px 0;
`;

const From = styled.div`
  grid-row: 1;
  grid-column: 2;
  place-self: stretch start;
  color: white;
  font-size: 12px;
  margin-top: 4px;
`;

const To = styled.div`
  grid-row: 2;
  grid-column: 2;
  place-self: stretch start;
  color: white;
  font-size: 12px;
`;
