import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createGlobalStyle } from "styled-components";

import App from "./App";

Sentry.init({
  dsn: "",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  #root {
    background-color: #3f4043;
    margin: 0;
    padding: 0;
    min-height: 100%;
    display: grid;
    grid-template: [root-start] "left right" [root-end] / [root-start] 1fr auto [root-end];
    overflow: hidden;

    > * {
      grid-area: root;
    }
  }

  @font-face {
      font-family: "HelveticaNeueLTStd";
      src: url(https://tile.livemap24.com/fonts/HelveticaNeueLTStd-Bd/HelveticaNeueLTStd-Bd.otf);
      font-weight: "bold"
    }

    @font-face {
      font-family: "HelveticaNeueLTStd";
      src: url(https://tile.livemap24.com/fonts/HelveticaNeueLTStd-Roman/HelveticaNeueLTStd-Roman.otf);
      font-weight: 400
    }

    @font-face {
      font-family: "BitterPro";
      src: url(https://tile.livemap24.com/fonts/BitterPro-ExtraBold/BitterPro-ExtraBold.otf);
      font-weight: "bold"
    }
`;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalStyle></GlobalStyle>
    <App />
  </React.StrictMode>
);
