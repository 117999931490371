import { useEffect } from "react";
import { useMap } from "../hooks/map";

export function RegionalTransitMapStopLabels({ maxZoom = 0 }) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    map.addSource("regional-stop-labels", {
      type: "geojson",
      data: null,
    });

    import("../data/regional-transit-map-stop-labels.json").then((stopLabels) =>
      (map as any)?.getSource("regional-stop-labels").setData(stopLabels)
    );

    // TODO: Remove `as any` when https://github.com/maplibre/maplibre-gl-js/issues/1888 has been fixed.
    (map as any).addLayer({
      id: "regional-stop-labels",
      type: "symbol",
      source: "regional-stop-labels",
      maxzoom: maxZoom,
      layout: {
        "text-field": ["get", "stop_name"],
        "text-font": ["HelveticaNeueLTStd-Cn"],
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          6,
          2,
          16,
          1800,
        ],
        "text-line-height": 1,
        "text-anchor": ["get", "text-anchor"],
        "text-rotate": ["coalesce", ["get", "text-rotate"], 0],
        "text-overlap": "always",
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-max-width": ["coalesce", ["get", "text-max-width"], Infinity],
      },
      paint: {
        "text-color": "white",
        "text-halo-color": "#3f4043",
        "text-halo-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          8,
          1,
          16,
          7,
        ],
        "text-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          maxZoom - 1,
          1,
          maxZoom,
          0,
        ],
      },
      filter: ["==", "text-scale", 1],
    });

    // TODO: Remove `as any` when https://github.com/maplibre/maplibre-gl-js/issues/1888 has been fixed.
    (map as any).addLayer({
      id: "regional-stop-labels-medium",
      type: "symbol",
      source: "regional-stop-labels",
      maxzoom: maxZoom,
      layout: {
        "text-field": ["get", "stop_name"],
        "text-font": ["HelveticaNeueLTStd-BdCn"],
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          6,
          1,
          16,
          1800,
        ],
        "text-anchor": ["get", "text-anchor"],
        "text-rotate": ["coalesce", ["get", "text-rotate"], 0],
        "text-overlap": "always",
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-max-width": ["coalesce", ["get", "text-max-width"], Infinity],
      },
      paint: {
        "text-color": "white",
        "text-halo-color": "#3f4043",
        "text-halo-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          8,
          1,
          16,
          ["*", 10, ["get", "text-scale"]],
        ],
        "text-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          maxZoom - 1,
          1,
          maxZoom,
          0,
        ],
      },
      filter: ["all", [">", "text-scale", 1], ["<", "text-scale", 1.2]],
    });

    // TODO: Remove `as any` when https://github.com/maplibre/maplibre-gl-js/issues/1888 has been fixed.
    (map as any).addLayer({
      id: "regional-stop-labels-large",
      type: "symbol",
      source: "regional-stop-labels",
      maxzoom: maxZoom,
      layout: {
        "text-field": ["get", "stop_name"],
        "text-font": ["HelveticaNeueLTStd-Bd"],
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          6,
          2,
          16,
          2300,
        ],
        "text-anchor": ["get", "text-anchor"],
        "text-rotate": ["coalesce", ["get", "text-rotate"], 0],
        "text-overlap": "always",
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-max-width": ["coalesce", ["get", "text-max-width"], Infinity],
        "text-justify": "left",
      },
      paint: {
        "text-color": "white",
        "text-halo-color": "#3f4043",
        "text-halo-width": ["*", 3, ["coalesce", ["get", "text-scale"], 1]],
        "text-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          maxZoom - 1,
          1,
          maxZoom,
          0,
        ],
      },
      filter: [">=", "text-scale", 1.2],
    });

    return () => {
      map?.removeLayer("regional-stop-labels");
      map?.removeLayer("regional-stop-labels-medium");
      map?.removeLayer("regional-stop-labels-large");
      map?.removeSource("regional-stop-labels");
    };
  }, [map, maxZoom]);
  return null;
}
