import { useEffect } from "react";
import { useMap } from "../hooks/map";

export function TransitMapStopLabels({ minZoom = 0 }) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    map.addSource("stop-labels", {
      type: "geojson",
      data: null,
    });

    import("../data/transit-map-stop-labels.json").then((stopLabels) =>
      (map as any)?.getSource("stop-labels").setData(stopLabels)
    );

    // TODO: Remove `as any` when https://github.com/maplibre/maplibre-gl-js/issues/1888 has been fixed.
    (map as any).addLayer({
      id: "stop-labels",
      type: "symbol",
      source: "stop-labels",
      minzoom: minZoom,
      layout: {
        "text-field": ["get", "stop_name"],
        "text-font": ["HelveticaNeueLTStd-Cn"],
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          8,
          1,
          16,
          140,
        ],
        "text-line-height": 1,
        "text-anchor": ["get", "text-anchor"],
        "text-rotate": ["coalesce", ["get", "text-rotate"], 0],
        "text-overlap": "always",
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-max-width": ["coalesce", ["get", "text-max-width"], Infinity],
      },
      paint: {
        "text-color": "white",
        "text-halo-color": "#3f4043",
        "text-halo-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          8,
          1,
          16,
          7,
        ],
        "text-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom,
          0,
          minZoom + 1,
          1,
        ],
      },
      filter: ["==", "text-scale", 1],
    });

    // TODO: Remove `as any` when https://github.com/maplibre/maplibre-gl-js/issues/1888 has been fixed.
    (map as any).addLayer({
      id: "stop-labels-medium",
      type: "symbol",
      source: "stop-labels",
      minzoom: minZoom,
      layout: {
        "text-field": ["get", "stop_name"],
        "text-font": ["HelveticaNeueLTStd-BdCn"],
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          8,
          ["*", 1, ["get", "text-scale"]],
          16,
          ["*", 140, ["get", "text-scale"]],
        ],
        "text-anchor": ["get", "text-anchor"],
        "text-rotate": ["coalesce", ["get", "text-rotate"], 0],
        "text-overlap": "always",
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-max-width": ["coalesce", ["get", "text-max-width"], Infinity],
      },
      paint: {
        "text-color": "white",
        "text-halo-color": "#3f4043",
        "text-halo-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          8,
          1,
          16,
          ["*", 10, ["get", "text-scale"]],
        ],
        "text-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom,
          0,
          minZoom + 1,
          1,
        ],
      },
      filter: ["all", [">", "text-scale", 1], ["<", "text-scale", 1.2]],
    });

    // TODO: Remove `as any` when https://github.com/maplibre/maplibre-gl-js/issues/1888 has been fixed.
    (map as any).addLayer({
      id: "stop-labels-large",
      type: "symbol",
      source: "stop-labels",
      minzoom: minZoom,
      layout: {
        "text-field": ["get", "stop_name"],
        "text-font": ["HelveticaNeueLTStd-Bd"],
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          8,
          ["*", 1, ["get", "text-scale"]],
          16,
          ["*", 140, ["get", "text-scale"]],
        ],
        "text-anchor": ["get", "text-anchor"],
        "text-rotate": ["coalesce", ["get", "text-rotate"], 0],
        "text-overlap": "always",
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-max-width": ["coalesce", ["get", "text-max-width"], Infinity],
        "text-justify": "left",
      },
      paint: {
        "text-color": "white",
        "text-halo-color": "#3f4043",
        "text-halo-width": ["*", 6, ["coalesce", ["get", "text-scale"], 1]],
        "text-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom,
          0,
          minZoom + 1,
          1,
        ],
      },
      filter: [">=", "text-scale", 1.2],
    });

    return () => {
      map?.removeLayer("stop-labels");
      map?.removeLayer("stop-labels-medium");
      map?.removeLayer("stop-labels-large");
      map?.removeSource("stop-labels");
    };
  }, [map, minZoom]);
  return null;
}
