import * as Sentry from "@sentry/react";
import { Core } from "livemap-gl";

import { Livemap } from "./Livemap";
import { Maplibre } from "./Maplibre";
import { Error } from "./Error";
import { getMapRegionFromUrl } from "../core/map";
import { useEffect, useState } from "react";
import { RegionalTransitMap } from "./RegionalTransitMap";
import { LocalTransitMap } from "./LocalTransitMap";
import { LngLatBoundsLike } from "maplibre-gl";

export interface MapProps {
  onMapReady(map: maplibregl.Map): void;
  onLivemapReady(livemap: Core): void;
}

const DEFAULT_MAX_BOUNDS: [[number, number], [number, number]] = [
  [10.381540016570995, 56.62546717344401],
  [21.003143647265148, 60.175135005236456],
];
const SHOW_REGIONAL_MAP_ZOOM_LEVEL = 1;

const CITY_VEHICLE_SCALE = [
  0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.5, 0.5, 1, 1.5, 2, 3, 5, 8, 10, 16, 24, 64,
  128, 256,
];

const REGIONAL_VEHICLE_SCALE = [
  0.1, 0.1, 0.1, 1, 1, 1, 1, 1, 4, 5, 6.6, 9.9, 16.5, 26.4, 50, 80, 150, 300,
  500, 900,
];

const CITY_LINE_SCALE = [
  0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.2, 0.4, 0.8, 1.6,
  3.2, 6.4, 12.8, 25.6, 51.2,
];

const REGIONAL_LINE_SCALE = [
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 4, 8, 16, 32, 64, 64, 64, 64,
];

const CITY_VEHICLE_BADGE_SCALE = [
  0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.1, 0.2,
  0.3, 0.4, 0.6, 0.9, 1.6, 2.2, 3,
];

const REGIONAL_VEHICLE_BADGE_SCALE = [
  0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.1, 0.15, 0.2, 0.3, 0.45,
  0.7, 1.4, 2.4, 4, 6, 8, 12,
];

const CITY_TOXEL_URL = "https://otraf-transit.veridict.net/v3";
const REGIONAL_TOXEL_URL = "https://otraf-transit-regional.veridict.net/v3";

export function TransitMap({ onMapReady, onLivemapReady }: MapProps) {
  const mapRegion = getMapRegionFromUrl();
  const [initialBounds, setInitialBounds] = useState<
    LngLatBoundsLike | undefined
  >();
  const [maxBounds, setMaxBounds] = useState(DEFAULT_MAX_BOUNDS);
  const [regionalZoomLevel, setRegionalZoomLevel] = useState(
    SHOW_REGIONAL_MAP_ZOOM_LEVEL
  );
  const [toxelUrl, setToxelUrl] = useState(CITY_TOXEL_URL);
  const [lineScale, setLineScale] = useState(CITY_LINE_SCALE);
  const [vehicleScale, setVehicleScale] = useState(CITY_VEHICLE_SCALE);
  const [vehicleBadgeScale, setVehicleBadgeScale] = useState(
    CITY_VEHICLE_BADGE_SCALE
  );

  useEffect(() => {
    if (mapRegion === "norrkoping") {
      setInitialBounds([
        [16.1, 58.55],
        [16.28, 58.67],
      ]);
      setMaxBounds([
        [15.8, 58.5],
        [16.65, 58.7],
      ]);
      setRegionalZoomLevel(1);
      setCityMode();
    } else if (mapRegion === "linkoping") {
      setInitialBounds([
        [15.49, 58.355],
        [15.74, 58.442],
      ]);
      setMaxBounds([
        [15.3, 58.2],
        [15.9, 58.5],
      ]);
      setRegionalZoomLevel(1);
      setCityMode();
    } else if (mapRegion === "regional") {
      setInitialBounds([
        [14.7, 57.6],
        [16.8, 59],
      ]);
      setMaxBounds([
        [10, 56],
        [21, 60],
      ]);
      setRegionalZoomLevel(24);
      setRegionalMode();
    } else {
      setMaxBounds(DEFAULT_MAX_BOUNDS);
    }
  }, [mapRegion, regionalZoomLevel]);

  return (
    <Maplibre
      id="map-root"
      onReady={onMapReady}
      maxBounds={maxBounds}
      initialBounds={initialBounds}
    >
      <Sentry.ErrorBoundary
        fallback={Error}
        beforeCapture={(scope) => scope.setTag("element", "Livemap")}
      >
        <Livemap
          toxelHost={toxelUrl}
          apiKey="4529223D1E66C085E601059BD8A69543"
          onReady={onLivemapReady}
          lineScale={lineScale}
          vehicleScale={vehicleScale}
          vehicleBadgeScale={vehicleBadgeScale}
        ></Livemap>
      </Sentry.ErrorBoundary>

      {(!mapRegion || mapRegion !== "regional") && (
        <LocalTransitMap minZoomLevel={regionalZoomLevel}></LocalTransitMap>
      )}
      {(!mapRegion || mapRegion === "regional") && (
        <RegionalTransitMap
          maxZoomLevel={regionalZoomLevel}
        ></RegionalTransitMap>
      )}
    </Maplibre>
  );

  function setRegionalMode() {
    setToxelUrl(REGIONAL_TOXEL_URL);
    setLineScale(REGIONAL_LINE_SCALE);
    setVehicleScale(REGIONAL_VEHICLE_SCALE);
    setVehicleBadgeScale(REGIONAL_VEHICLE_BADGE_SCALE);
  }

  function setCityMode() {
    setToxelUrl(CITY_TOXEL_URL);
    setLineScale(CITY_LINE_SCALE);
    setVehicleScale(CITY_VEHICLE_SCALE);
    setVehicleBadgeScale(CITY_VEHICLE_BADGE_SCALE);
  }
}
