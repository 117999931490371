import React, { useEffect, useRef, useState } from "react";
import maplibregl, { CameraOptions, LngLatBoundsLike } from "maplibre-gl";
import styled from "styled-components";

import "maplibre-gl/dist/maplibre-gl.css";
import { getMapUrlParams, setMapUrlParams } from "../core/map";

const LINKOPING_CAMERA: CameraOptions = {
  center: [15.62605, 58.40013],
  zoom: 12,
};

export interface MaplibreProps {
  id: string;
  initialCamera?: CameraOptions;
  initialBounds?: LngLatBoundsLike;
  authToken?: string;
  maxBounds?: [[number, number], [number, number]];
}

export interface MaplibreDispatch {
  /** Called once the map have been initialized, and are ready for use. */
  onReady?(map: maplibregl.Map): void;
  /** Called when the camera has stopped moving */
  onCameraMoved?(cameraOptions: CameraOptions): void;
  /** Called while the camera is moving */
  onCameraMove?(cameraOptions: CameraOptions): void;
}

function createMap(
  id: string,
  camera?: CameraOptions,
  bounds?: LngLatBoundsLike,
  maxBounds?: LngLatBoundsLike
) {
  // Do this to avoid including maplibre-gl code in the bundle.
  // It is a peerDependency(and devDependency)
  const map = new maplibregl.Map({
    ...(camera ?? LINKOPING_CAMERA),
    bounds: bounds,
    style: {
      version: 8,
      glyphs: "https://tile.livemap24.com/fonts/{fontstack}/{range}.pbf",
      sources: {},
      layers: [],
    },
    container: id,
    minZoom: 5,
    maxZoom: 16,
    pitchWithRotate: false,
    dragRotate: false,
    touchPitch: false,
  });

  map.touchZoomRotate.disableRotation();
  return map;
}

type Props = MaplibreProps &
  MaplibreDispatch &
  React.PropsWithChildren<MaplibreProps>;

export function Maplibre({
  id,
  initialCamera,
  initialBounds,
  maxBounds,
  children,
  onReady,
  onCameraMove,
  onCameraMoved,
}: Props) {
  const [map, setMap] = useState<maplibregl.Map | null>(null);
  const cameraRef = useRef<CameraOptions | null>(null);

  useEffect(() => {
    const camera = getMapUrlParams();
    const newMap = createMap(id, camera ?? initialCamera, initialBounds);
    setMap(newMap);
    cameraRef.current = camera ?? initialCamera ?? LINKOPING_CAMERA;

    return () => {
      newMap?.remove();
      setMap(null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (window as any).maplibre;
    };
  }, [id, initialCamera, initialBounds]);

  useEffect(() => {
    if (!map || !maxBounds) return;

    const center = map.getCenter();
    map.setMaxBounds(maxBounds);

    if (!map.getMaxBounds()?.contains(center)) {
      map.jumpTo({ center: map.getMaxBounds()?.getCenter() });
    }
  }, [map, maxBounds]);

  useEffect(() => {
    if (!map) return;

    const cameraMove = () => {
      if (!cameraRef.current) return;

      const camera = cameraRef.current;

      camera.center = map.getCenter();
      camera.zoom = map.getZoom();
      camera.bearing = map.getBearing();
      camera.pitch = map.getPitch();

      onCameraMove && onCameraMove(camera);
    };

    const cameraMoveEnd = () => {
      setMapUrlParams(cameraRef.current);
      cameraRef.current && onCameraMoved && onCameraMoved(cameraRef.current);
    };

    map.on("move", cameraMove);
    map.on("zoom", cameraMove);
    map.on("dragend", cameraMoveEnd);
    map.on("zoomend", cameraMoveEnd);
    map.on("rotateend", cameraMoveEnd);
    map.on("load", () => onReady && onReady(map));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).maplibre = map;
  }, [map, onCameraMove, onCameraMoved, onReady]);

  return <MapContainer id={id}>{children}</MapContainer>;
}

const MapContainer = styled.div`
  grid-area: container;
  width: 100%;
  height: 100%;
`;
