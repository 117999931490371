import { useContext } from "react";
import { CameraOptions, Map } from "maplibre-gl";

import { MaplibreContext } from "../contexts/maplibre-context";

export function useMap(): Map | null {
  return useContext(MaplibreContext) || null;
}

export function useLastCamera(): CameraOptions | undefined {
  const json = window.localStorage.getItem("LAST_CAMERA");

  if (!json) {
    return undefined;
  }

  return JSON.parse(json);
}

export function useSetLastCamera() {
  return (camera: CameraOptions) =>
    window.localStorage.setItem("LAST_CAMERA", JSON.stringify(camera));
}
