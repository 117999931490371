import { Core } from "livemap-gl";

import circleModel from "./vehicle-models/pointy-circle.model.json";

const DEFAULT_VEHICLE_SCALE_AT_ZOOM = [
  0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.5, 0.5, 1, 1.5, 2, 3, 5, 8, 10, 16, 24, 64,
  128, 256,
];

const DEFAULT_LINE_SCALE = [
  0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.2, 0.4, 0.8, 1.6,
  3.2, 6.4, 12.8, 25.6, 51.2,
];

const DEFAULT_VEHICLE_BADGE_SCALE = [
  0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.1, 0.2,
  0.3, 0.4, 0.6, 0.9, 1.6, 2.2, 3,
];

export function createLivemap(
  apiKey?: string,
  lineScale?: number[],
  vehicleScale?: number[],
  vehicleBadgeScale?: number[]
): Core {
  const livemap = new Core({
    apiKey: apiKey,
    spritePainterMinZoom: 6,
  });
  livemap.modules.watermark.enabled = false;
  livemap.setVehicleFilter((v) => v.hasDelayModel === true);
  livemap.onInitialized(() => {
    livemap.configure({
      reversePreventionThreshold: 30000, // 30 seconds
      toxelDepth: 12,
    });

    applyLivemapTheme(livemap, lineScale, vehicleScale, vehicleBadgeScale);
  });

  livemap.modules.add("toxelFieldsOverride", {
    enabled: true,
    getToxelFields: function (lod, id) {
      return ~(-1 << 24); // ToxelFields.ALL
    },
  });

  return livemap;
}

function applyLivemapTheme(
  livemap: Core,
  lineScale?: number[],
  vehicleScale?: number[],
  vehicleBadgeScale?: number[]
) {
  livemap.modules.route.configure({
    useTripBgColor: true,
    colorPast: "#3f4043",
    lineWidth: 14,
    lineScale: lineScale ?? DEFAULT_LINE_SCALE,
  });

  livemap.modules.stopPin.enabled = false;
  livemap.modules.stopBadge.enabled = false;

  livemap.modules.vehicleBadge.configure({
    vehicleBadgeStyle: {
      colorMode: 0,
      textColorMode: 1,
      smallOffsetY: 42,
      borderThickness: 0,
      textColor: "#ffffff",
      fillColor: "rgba(0, 0, 0, 0)",
      paddingX: 0,
      nameFont: {
        family: "BitterPro, 'Times New Roman', Times, serif",
        size: 56,
        weight: "bold",
      },
      useLargeBadgeForSelectedVehicle: false,
      offlineTextColor: "#ffffff",
    },
    levels: {
      small: 1,
      medium: 99,
      large: 99,
    },
    scale: vehicleBadgeScale ?? DEFAULT_VEHICLE_BADGE_SCALE,
  });

  const circleId = livemap.modules.vehicle.addVehicleModel(circleModel);

  livemap.modules.vehicle.configure({
    useTripBgColor: true,
    modelSelector: () => circleId,
    vehicleScale: vehicleScale ?? DEFAULT_VEHICLE_SCALE_AT_ZOOM,
    selectedVehicleSizeMultiplier: 1,
    vehicleSelectionStyle: 1,
    drawShadow: true,
    offlineOpacity: 0.7,
  });
}
