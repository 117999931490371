import { PropsWithChildren, ReactElement } from "react";
import styled from "styled-components";

export interface PanelsProps {
  visiblePanelIndex: number;
  onVisiblePanelIndexChanged: (index: number) => void;
  hideContent?: boolean;
  onExpand?: () => void;
  children: ReactElement<PanelProps>[];
}

export function Panels({
  visiblePanelIndex,
  onVisiblePanelIndexChanged,
  hideContent = false,
  onExpand,
  children,
}: PanelsProps) {
  if (children.length === 0) {
    return null;
  }

  return (
    <PanelsContainer>
      <PanelSelector
        hideContent={hideContent}
        visiblePanelIndex={visiblePanelIndex}
        onVisiblePanelIndexChanged={onVisiblePanelIndexChanged}
        onExpand={onExpand}
      >
        {children}
      </PanelSelector>
      <ExpandButton expanded={!hideContent} onClick={onExpand}></ExpandButton>
      <Content>{!hideContent && children[visiblePanelIndex]}</Content>
    </PanelsContainer>
  );
}

export interface PanelProps extends PropsWithChildren {
  header: string;
}

export function Panel({ children }: PanelProps) {
  return <>{children}</>;
}

const PanelsContainer = styled.div`
  display: grid;
  grid-template-columns: max-content min-content max-content;
  grid-template-rows: auto auto;
  font-family: "HelveticaNeueLTStd";
`;

const Content = styled.div`
  grid-column: 1 / 4;
`;

export interface PanelSelectorProps {
  onPanelIndexChanged: (index: number) => void;
  index: number;
  children: ReactElement<PanelProps>[];
}

function PanelSelector({
  visiblePanelIndex,
  onVisiblePanelIndexChanged,
  hideContent,
  onExpand,
  children,
}: PanelsProps) {
  return (
    <SelectorContainer
      onClick={(e) => {
        if (hideContent) {
          onExpand && onExpand();
          e.preventDefault();
        }
      }}
    >
      <Select
        disabled={hideContent}
        value={visiblePanelIndex}
        onChange={(element) =>
          onVisiblePanelIndexChanged(parseInt(element.target.value))
        }
      >
        {children.map((c, i) => (
          <option value={i} key={i} hidden={visiblePanelIndex === i}>
            {c.props.header}
          </option>
        ))}
      </Select>
    </SelectorContainer>
  );
}

const SelectorContainer = styled.div`
  width: max-content;
`;

const Select = styled.select`
  grid-column: 1;
  grid-row: 1;
  appearance: none;
  font-family: "HelveticaNeueLTStd";
  font-size: 20px;
  background-color: transparent;
  border: none;
  color: white;
  margin-bottom: 8px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBmaWxsPSJub25lIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjU0IDIuMjNhLjc1Ljc1IDAgMCAwLTEuMDggMGwtNi4yNSA2LjVhLjc1Ljc1IDAgMSAwIDEuMDggMS4wNEwxMiAzLjgzMmw1LjcxIDUuOTM4YS43NS43NSAwIDEgMCAxLjA4LTEuMDRsLTYuMjUtNi41Wm0wIDE5LjU0YS43NS43NSAwIDAgMS0xLjA4IDBsLTYuMjUtNi41YS43NS43NSAwIDEgMSAxLjA4LTEuMDRMMTIgMjAuMTY4bDUuNzEtNS45MzhhLjc1Ljc1IDAgMSAxIDEuMDggMS4wNGwtNi4yNSA2LjVaIiBmaWxsPSIjZGMyMjJjIi8+Cjwvc3ZnPgo=");
  background-position: 0 0, right 0;
  background-repeat: no-repeat;
  background-size: 16px;
  outline: none;
  padding: 0 0 0 20px;

  option {
    background-color: #3f4043;

    &:hover {
      background-color: red;
    }
  }

  :disabled {
    opacity: 1;
    background-image: none;
  }

  :focus {
    outline: none;
  }

  @media screen and (max-width: 220px) {
    padding: 0 0 0 16px;
  }
`;

interface ExpandButtonProps {
  expanded: boolean;
}

const ExpandButton = styled.button<ExpandButtonProps>`
  grid-column: 2;
  grid-row: 1;
  place-self: start start;
  appearance: none;
  border: none;
  background-color: transparent;
  width: 18px;
  height: 18px;
  background-image: ${(p) =>
    p.expanded
      ? "url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0ibTQuMjIgMTEuMjM5YTAuNzUgMC43NSAwIDAgMSAxLjA2IDBsNi43MiA2LjcyIDYuNzItNi43MmEwLjc1IDAuNzUgMCAxIDEgMS4wNiAxLjA2bC03LjI1IDcuMjVhMC43NSAwLjc1IDAgMCAxLTEuMDYgMGwtNy4yNS03LjI1YTAuNzUgMC43NSAwIDAgMSAwLTEuMDZ6IiBmaWxsPSIjZGMyMjJjIi8+CjxwYXRoIGQ9Im00LjIyIDUuMjM5NGEwLjc1IDAuNzUgMCAwIDEgMS4wNiAwbDYuNzIgNi43MiA2LjcyLTYuNzJhMC43NSAwLjc1IDAgMSAxIDEuMDYgMS4wNmwtNy4yNSA3LjI1YTAuNzUgMC43NSAwIDAgMS0xLjA2IDBsLTcuMjUtNy4yNWEwLjc1IDAuNzUgMCAwIDEgMC0xLjA2eiIgZmlsbD0iI2RjMjIyYyIvPgo8L3N2Zz4K')"
      : "url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0ibTQuMjIgMTMuNTNhMC43NSAwLjc1IDAgMCAwIDEuMDYgMGw2LjcyLTYuNzIgNi43MiA2LjcyYTAuNzUgMC43NSAwIDEgMCAxLjA2LTEuMDZsLTcuMjUtNy4yNWEwLjc1IDAuNzUgMCAwIDAtMS4wNiAwbC03LjI1IDcuMjVhMC43NSAwLjc1IDAgMCAwIDAgMS4wNnoiIGZpbGw9IiNkYzIyMmMiLz4KPHBhdGggZD0ibTQuMjIgMTkuNTNhMC43NSAwLjc1IDAgMCAwIDEuMDYgMGw2LjcyLTYuNzIgNi43MiA2LjcyYTAuNzUgMC43NSAwIDEgMCAxLjA2LTEuMDZsLTcuMjUtNy4yNWEwLjc1IDAuNzUgMCAwIDAtMS4wNiAwbC03LjI1IDcuMjVhMC43NSAwLjc1IDAgMCAwIDAgMS4wNnoiIGZpbGw9IiNkYzIyMmMiLz4KPC9zdmc+Cg==')"};
`;
