import { useEffect } from "react";
import { Map } from "maplibre-gl";

import { useMap } from "../hooks/map";

export function TransitMapStops({ minZoom = 0 }) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    addLineStops(map, minZoom);
    addPolygonStops(map, minZoom);

    return () => {
      map?.removeLayer("line-stops");
      map?.removeLayer("polygon-stops-line");
      map?.removeLayer("polygon-stops");
      map?.removeLayer("polygon-stops-stroke");
      map?.removeLayer("polygon-stop-labels");
      map?.removeSource("line-stops");
      map?.removeSource("polygon-stops");
      map?.removeSource("polygon-stop-labels");
    };
  }, [map, minZoom]);

  return null;
}

function addLineStops(map: Map, minZoom: number) {
  map.addSource("line-stops", {
    type: "geojson",
    data: null,
  });

  import("../data/transit-map-line-stops.json").then((lineStops) => {
    (map as any)?.getSource("line-stops")?.setData(lineStops);
  });

  // TODO: Remove `as any` when https://github.com/maplibre/maplibre-gl-js/issues/1888 has been fixed.
  (map as any).addLayer(
    {
      id: "line-stops",
      type: "line",
      source: "line-stops",
      minzoom: minZoom - 1,
      layout: {
        "line-cap": "butt",
      },
      paint: {
        "line-color": ["get", "stroke"],
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          1,
          ["/", 2, ["get", "stroke-width"]],
          17,
          ["*", 35, ["get", "stroke-width"]],
        ],
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom - 1,
          0,
          minZoom,
          1,
        ],
      },
    },
    "rail-routes"
  );
}

function addPolygonStops(map: Map, minZoom: number) {
  map.addSource("polygon-stops", {
    type: "geojson",
    data: null,
  });

  import("../data/transit-map-polygon-stops.json").then((polygonStops) => {
    (map as any)?.getSource("polygon-stops").setData(polygonStops);
  });

  map.addSource("polygon-stop-labels", {
    type: "geojson",
    data: null,
  });

  import("../data/transit-map-polygon-stop-labels.json").then((stopLabels) =>
    (map as any)?.getSource("polygon-stop-labels").setData(stopLabels)
  );

  map.addLayer({
    id: "polygon-stops-line",
    type: "line",
    source: "polygon-stops",
    minzoom: minZoom - 1,
    layout: { "line-cap": "butt" },
    paint: {
      "line-color": ["get", "stroke"],
      "line-width": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        11,
        2,
        17,
        80,
      ],
      "line-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        minZoom - 1,
        0,
        minZoom,
        1,
      ],
    },
    filter: ["==", "$type", "LineString"],
  });

  map.addLayer({
    id: "polygon-stops",
    type: "fill",
    source: "polygon-stops",
    minzoom: minZoom - 1,
    layout: {},
    paint: {
      "fill-color": ["get", "fill"],
      "fill-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        minZoom - 1,
        0,
        minZoom,
        1,
      ],
    },
    filter: ["==", "$type", "Polygon"],
  });

  map.addLayer({
    id: "polygon-stops-stroke",
    type: "line",
    source: "polygon-stops",
    minzoom: minZoom,
    layout: {},
    paint: {
      "line-color": ["get", "stroke"],
      "line-width": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        11,
        2,
        17,
        56,
      ],
      "line-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        minZoom - 1,
        0,
        minZoom,
        1,
      ],
    },
    filter: ["==", "$type", "Polygon"],
  });

  // TODO: Find out why we get warnings in the console. It has something to do with text-field
  map.addLayer({
    id: "polygon-stop-labels",
    type: "symbol",
    source: "polygon-stop-labels",
    minzoom: minZoom - 1,
    layout: {
      "text-field": ["format", ["at", 0, ["get", "routes"]]],
      "text-font": ["BitterPro-Bold"],
      "text-size": ["interpolate", ["exponential", 2], ["zoom"], 8, 2, 16, 200],
      "text-rotate": ["coalesce", ["get", "text-rotate"], 0],
      "text-overlap": "always",
      "text-offset": [0, -0.3],
      "text-pitch-alignment": "map",
      "text-rotation-alignment": "map",
    },
    paint: {
      "text-color": ["coalesce", ["get", "text-color"], "white"],
      "text-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        minZoom - 1,
        0,
        minZoom,
        1,
      ],
    },
    filter: ["==", ["length", ["get", "routes"]], 1],
  });
}
