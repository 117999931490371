import { CameraOptions, LngLat } from "maplibre-gl";

export function getMapUrlParams() {
  const parts = window.location.pathname.split("/");
  var cameraPath = parts.filter((part) => part.startsWith("@"))[0];

  const cameraParts = cameraPath?.split(",");

  if (!cameraParts || cameraParts?.length < 3) return null;

  const camera = {
    center: {
      lat: parseValue(cameraParts[0].substring(1)),
      lng: parseValue(cameraParts[1]),
    },
    zoom: parseValue(cameraParts[2]?.substring(-1)),
    bearing: parseValue(cameraParts[3]?.substring(-1)),
    pitch: parseValue(cameraParts[4]?.substring(-1)),
  };

  return camera as CameraOptions;
}

export function setMapUrlParams(camera: CameraOptions | null) {
  if (!camera) return;

  let lng = (camera.center as LngLat).lng.toFixed(7);
  let lat = (camera.center as LngLat).lat.toFixed(7);

  let cameraPath = `@${lat},${lng},${camera.zoom?.toFixed(2)}z`;
  if (camera.bearing) cameraPath += `,${camera.bearing.toFixed(1)}b`;
  if (camera.pitch) cameraPath += `,${camera.pitch.toFixed(1)}p`;

  const pathsParts = window.location.pathname.split("/");
  let previousPath = pathsParts.reduce((prev, curr) => {
    if (curr.startsWith("@") || !curr) {
      return prev;
    }

    return `${prev}/${curr}`;
  }, "");

  window.history.replaceState(
    null,
    "",
    `${previousPath}/${cameraPath}${window.location.search}`
  );
}

export function getMapRegionFromUrl() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get("region");
}

function parseValue(valueStr: string) {
  const value = parseFloat(valueStr);
  if (isNaN(value)) return null;

  return value;
}
