import { useEffect } from "react";

import { useMap } from "../hooks/map";

interface ImageOnMapProps {
  id: string;
  url: string;
  coordinates: [
    [number, number],
    [number, number],
    [number, number],
    [number, number]
  ];
  minZoom: number;
}

export function ImageOnMap({ id, url, coordinates, minZoom }: ImageOnMapProps) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    map.addSource(id, {
      type: "image",
      url: url,
      coordinates: coordinates,
    });

    map.addLayer({
      id: id,
      type: "raster",
      source: id,
      minzoom: minZoom - 1,
      paint: {
        "raster-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom - 1,
          0,
          minZoom,
          1,
        ],
      },
    });

    return () => {
      map?.removeLayer(id);
      map?.removeSource(id);
    };
  }, [map, id, url, coordinates, minZoom]);

  return null;
}
