import { useEffect } from "react";
import { useMap } from "../hooks/map";

export function GeographicMapLegend({ minZoom = 0 }) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;
    map.addSource("geography-legend", {
      type: "geojson",
      data: null,
    });

    import("../data/geographic-features-legend.json").then((data) => {
      (map?.getSource("geography-legend") as any).setData(data);
    });

    map.addSource("geography-legend-labels", {
      type: "geojson",
      data: null,
    });

    import("../data/geographic-features-legend-labels.json").then((data) => {
      (map?.getSource("geography-legend-labels") as any).setData(data);
    });

    (map as any).addLayer({
      id: "geography-legend-lines",
      type: "line",
      source: "geography-legend",
      minzoom: minZoom + 1,
      layout: {
        "line-join": "bevel",
        "line-cap": "butt",
      },
      paint: {
        "line-color": ["get", "stroke"],
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          1,
          1,
          17,
          ["*", 35, ["get", "stroke-width"]],
        ],
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom,
          0,
          minZoom + 1,
          1,
        ],
      },
      filter: ["==", "$type", "LineString"],
    });

    map.addLayer({
      id: "geography-legend-polygons",
      type: "fill",
      source: "geography-legend",
      minzoom: minZoom + 1,
      layout: {},
      paint: {
        "fill-color": ["get", "fill"],
        "fill-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom,
          0,
          minZoom + 1,
          1,
        ],
      },
      filter: ["==", "$type", "Polygon"],
    });

    map.addLayer({
      id: "geography-legend-polygons-stroke",
      type: "line",
      source: "geography-legend",
      minzoom: minZoom + 1,
      layout: {},
      paint: {
        "line-color": ["get", "stroke"],
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          11,
          2,
          17,
          56,
        ],
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom,
          0,
          minZoom + 1,
          1,
        ],
      },
      filter: ["==", "$type", "Polygon"],
    });

    (map as any).addLayer({
      id: "geography-legend-labels",
      type: "symbol",
      source: "geography-legend-labels",
      minzoom: minZoom,
      layout: {
        "text-field": ["get", "text"],
        "text-font": ["HelveticaNeueLTStd-Cn"],
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          8,
          ["*", 1, ["get", "text-scale"]],
          16,
          ["*", 140, ["get", "text-scale"]],
        ],
        "text-line-height": 1,
        "text-anchor": ["get", "text-anchor"],
        "text-overlap": "always",
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-max-width": Infinity,
      },
      paint: {
        "text-color": "white",
        "text-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom,
          0,
          minZoom + 1,
          1,
        ],
      },
    });

    return () => {
      map?.removeLayer("geography-legend-lines");
      map?.removeLayer("geography-legend-polygons");
      map?.removeLayer("geography-legend-polygons-stroke");
      map?.removeLayer("geography-legend-labels");
      map?.removeSource("geography-legend");
      map?.removeSource("geography-legend-labels");
    };
  }, [map, minZoom]);
  return null;
}
