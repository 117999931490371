import { useEffect } from "react";
import { useMap } from "../hooks/map";

export function TransitMapRoutes({ minZoom = 0 }) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    map.addSource("routes", {
      type: "geojson",
      data: null,
    });

    import("../data/transit-map-routes.json").then((routes) => {
      (map as any)?.getSource("routes").setData(routes);
    });

    // TODO: Remove `as any` when https://github.com/maplibre/maplibre-gl-js/issues/1888 has been fixed.
    (map as any).addLayer({
      id: "routes",
      type: "line",
      source: "routes",
      minzoom: minZoom - 1,
      layout: {
        "line-join": "round",
        "line-cap": "square",
      },
      paint: {
        "line-color": ["get", "stroke"],
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          1,
          ["/", 2, ["get", "stroke-width"]],
          17,
          ["*", 35, ["get", "stroke-width"]],
        ],
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom - 1,
          0,
          minZoom,
          1,
        ],
      },
    });

    // TODO: Remove `as any` when https://github.com/maplibre/maplibre-gl-js/issues/1888 has been fixed.
    (map as any).addLayer({
      id: "rail-routes",
      type: "line",
      source: "routes",
      minzoom: minZoom - 1,
      layout: {
        "line-join": "round",
        "line-cap": "square",
      },
      paint: {
        "line-color": "#585856",
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          1,
          ["/", 3, ["get", "stroke-width"]],
          17,
          ["*", 17, ["get", "stroke-width"]],
        ],
        "line-dasharray": [0.9, 0.3],
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom - 1,
          0,
          minZoom,
          1,
        ],
      },
      filter: ["coalesce", ["get", "is_rail"], false],
    });

    return () => {
      map?.removeLayer("routes");
      map?.removeLayer("rail-routes");
      map?.removeSource("routes");
    };
  }, [map, minZoom]);

  return null;
}
