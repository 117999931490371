import { FallbackRender } from "@sentry/react";
import styled from "styled-components";

export const Error: FallbackRender = ({
  error,
  componentStack,
  resetError,
}) => {
  return (
    <Container>
      <div>You have encountered an error</div>
      <div>{error.toString()}</div>
      <div>{componentStack}</div>
      <button
        onClick={() => {
          resetError();
        }}
      >
        Click here to reset!
      </button>
    </Container>
  );
};

const Container = styled.div`
  grid-area: root;
  width: 100%;
  height: 100%;
  place-self: start center;
`;
