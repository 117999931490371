import { useEffect } from "react";
import { Map } from "maplibre-gl";

import { useMap } from "../hooks/map";

export function RegionalTransitMapStops({ maxZoom = 0 }) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    addLineStops(map, maxZoom);
    addPolygonStops(map, maxZoom);

    return () => {
      map?.getLayer("regional-line-stops") &&
        map.removeLayer("regional-line-stops");
      map?.getLayer("regional-polygon-stops-line") &&
        map?.removeLayer("regional-polygon-stops-line");
      map?.getLayer("regional-polygon-stops") &&
        map?.removeLayer("regional-polygon-stops");
      map?.getLayer("regional-polygon-stops-stroke") &&
        map?.removeLayer("regional-polygon-stops-stroke");
      map?.getLayer("regional-polygon-stop-labels") &&
        map?.removeLayer("regional-polygon-stop-labels");
      map?.getLayer("regional-line-stops") &&
        map?.removeSource("regional-line-stops");
      map?.getSource("regional-polygon-stops") &&
        map?.removeSource("regional-polygon-stops");
      map?.getSource("regional-polygon-stop-labels") &&
        map?.removeSource("regional-polygon-stop-labels");
    };
  }, [map, maxZoom]);

  return null;
}

function addLineStops(map: Map, maxZoom: number) {
  map.addSource("regional-line-stops", {
    type: "geojson",
    data: null,
  });

  import("../data/regional-transit-map-line-stops.json").then((lineStops) => {
    (map as any)?.getSource("regional-line-stops")?.setData(lineStops);
  });

  // TODO: Remove `as any` when https://github.com/maplibre/maplibre-gl-js/issues/1888 has been fixed.
  (map as any).addLayer(
    {
      id: "regional-line-stops",
      type: "line",
      source: "regional-line-stops",
      maxzoom: maxZoom,
      layout: {
        "line-cap": "butt",
      },
      paint: {
        "line-color": ["get", "stroke"],
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          1,
          ["/", 2, ["get", "stroke-width"]],
          17,
          ["*", 35, ["get", "stroke-width"]],
        ],
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          maxZoom - 1,
          1,
          maxZoom,
          0,
        ],
      },
    },
    "regional-rail-routes"
  );
}

function addPolygonStops(map: Map, maxZoom: number) {
  map.addSource("regional-polygon-stops", {
    type: "geojson",
    data: null,
  });

  import("../data/regional-transit-map-polygon-stops.json").then(
    (polygonStops) => {
      (map as any)?.getSource("regional-polygon-stops").setData(polygonStops);
    }
  );

  map.addSource("regional-polygon-stop-labels", {
    type: "geojson",
    data: null,
  });

  import("../data/regional-transit-map-polygon-stop-labels.json").then(
    (polygonStopLabels) => {
      (map as any)
        ?.getSource("regional-polygon-stop-labels")
        .setData(polygonStopLabels);
    }
  );

  (map as any).addLayer({
    id: "regional-polygon-stops-line",
    type: "line",
    source: "regional-polygon-stops",
    maxzoom: maxZoom,
    layout: { "line-cap": "butt" },
    paint: {
      "line-color": ["get", "stroke"],
      "line-width": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        1,
        ["/", 2, ["get", "stroke-width"]],
        17,
        ["*", 35, ["get", "stroke-width"]],
      ],
      "line-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        maxZoom - 1,
        1,
        maxZoom,
        0,
      ],
    },
    filter: ["==", "$type", "LineString"],
  });

  map.addLayer({
    id: "regional-polygon-stops",
    type: "fill",
    source: "regional-polygon-stops",
    maxzoom: maxZoom,
    layout: {},
    paint: {
      "fill-color": ["get", "fill"],
      "fill-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        maxZoom - 1,
        1,
        maxZoom,
        0,
      ],
    },
    filter: ["==", "$type", "Polygon"],
  });

  (map as any).addLayer({
    id: "regional-polygon-stops-stroke",
    type: "line",
    source: "regional-polygon-stops",
    maxzoom: maxZoom,
    layout: {},
    paint: {
      "line-color": ["get", "stroke"],
      "line-width": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        1,
        ["/", 2, ["get", "stroke-width"]],
        17,
        ["*", 35, ["get", "stroke-width"]],
      ],
      "line-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        maxZoom - 1,
        1,
        maxZoom,
        0,
      ],
    },
    filter: ["==", "$type", "Polygon"],
  });

  // TODO: Find out why we get warnings in the console. It has something to do with text-field
  map.addLayer({
    id: "regional-polygon-stop-labels",
    type: "symbol",
    source: "regional-polygon-stop-labels",
    maxzoom: maxZoom - 1,
    layout: {
      "text-field": ["format", ["at", 0, ["get", "routes"]]],
      "text-font": ["BitterPro-Bold"],
      "text-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        6,
        2,
        16,
        1500,
      ],
      "text-rotate": ["coalesce", ["get", "text-rotate"], 0],
      "text-overlap": "always",
      "text-offset": [0, -0.3],
      "text-pitch-alignment": "map",
      "text-rotation-alignment": "map",
      "text-padding": 20,
      "text-max-width": Infinity,
    },
    paint: {
      "text-color": ["coalesce", ["get", "text-color"], "white"],
      "text-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        maxZoom - 1,
        1,
        maxZoom,
        0,
      ],
    },
    filter: ["==", ["length", ["get", "routes"]], 1],
  });
}
