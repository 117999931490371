import { useEffect, useRef, useState } from "react";
import {
  bindMapbox,
  Core,
  MapboxMapLike,
  SelectedTripDataEvent,
  StopSelectedEvent,
  TimeChangedEvent,
  Trip,
  Vehicle,
} from "livemap-gl";

import { createLivemap } from "../core/livemap";
import { useMap } from "../hooks/map";

export interface LivemapProps {
  toxelHost?: string;
  linkWebSocketUrl?: string;
  apiKey: string;
  lineScale?: number[];
  vehicleScale?: number[];
  vehicleBadgeScale?: number[];
}

export interface LivemapDispatch {
  /** Called once the livemap have been initialized, and are ready for use. */
  onReady?(livemap: Core): void;
  /** Called when a stop has been selected on the map */
  onStopSelected?(event: StopSelectedEvent): void;
  /** Called when a trip has been deselected from the map */
  onStopDeselected?(): void;
  /** Called each time the time on the traffic simulation changes */
  onTimeChanged?(event: TimeChangedEvent): void;
  /** Called when a vehicle has been selected on the map */
  onVehicleSelected?(vehicle: Vehicle): void;
  /** Called when trip information has been loaded */
  onTripLoaded?(trip: Trip): void;
  /** Called when a trip has been cleared from the map */
  onTripCleared?(): void;
}

type Props = LivemapDispatch & LivemapProps;

export function Livemap({
  toxelHost,
  linkWebSocketUrl,
  apiKey,
  lineScale,
  vehicleScale,
  vehicleBadgeScale,
  onReady,
  onVehicleSelected,
  onTripCleared,
  onStopSelected,
  onStopDeselected,
  onTimeChanged,
  onTripLoaded,
}: Props) {
  const livemapRef = useRef<Core | null>();
  const [isReady, setIsReady] = useState(false);
  const maplibre = useMap();

  // Set up livemap when the component is mounted.
  useEffect(() => {
    if (livemapRef.current) return;

    livemapRef.current = createLivemap(
      apiKey,
      lineScale,
      vehicleScale,
      vehicleBadgeScale
    );

    (window as any).livemap = livemapRef.current;
    // Dispose livemap when the component gets unmounted.
    return () => {
      // Check isReady to see if it initialized, no need to dispose otherwise
      if (livemapRef.current && !livemapRef.current.isDisposed && isReady) {
        livemapRef.current?.dispose();
      }

      livemapRef.current = null;
      delete (window as any).livemap;
    };
  }, [apiKey, isReady, lineScale, vehicleScale, vehicleBadgeScale]);

  // Set up livemap once mapbox is available.
  useEffect(() => {
    if (!maplibre || !livemapRef.current || isReady) return;

    bindMapbox(livemapRef.current, maplibre as unknown as MapboxMapLike);

    setIsReady(true);
  }, [maplibre, setIsReady, isReady]);

  useEffect(() => {
    if (!livemapRef.current) {
      return;
    }

    const livemap = livemapRef.current;

    onVehicleSelected &&
      livemap.onTripSelected(() => onVehicleSelected(livemap.selectedVehicle!));

    onTripLoaded &&
      livemap.onTripSelectedData((event: SelectedTripDataEvent) =>
        onTripLoaded(event.trip)
      );

    onTripCleared && livemap.onTripDeselected(onTripCleared);

    onStopSelected && livemap.onStopSelected(onStopSelected);
    onStopDeselected && livemap.onStopDeselected(onStopDeselected);
    onTimeChanged && livemap.onTimeChanged(onTimeChanged);

    onReady && onReady(livemapRef.current);
  }, [
    isReady,
    onReady,
    onVehicleSelected,
    onTripLoaded,
    onTripCleared,
    onStopSelected,
    onStopDeselected,
    onTimeChanged,
  ]);

  useEffect(() => {
    livemapRef.current?.configure({ host: toxelHost });
  }, [toxelHost]);

  useEffect(() => {
    if (!linkWebSocketUrl) return;

    livemapRef.current?.modules.linkVehicle.configure({
      webSocketURL: linkWebSocketUrl,
      maxAge: Infinity,
    });
  }, [linkWebSocketUrl]);

  useEffect(() => {
    if ((lineScale?.length ?? 0) < 20) {
      console.warn("lineScale should contain at least 20 levels");
      return;
    }

    livemapRef.current?.modules.route.configure({
      lineScale: lineScale,
    });
  }, [lineScale]);

  useEffect(() => {
    if ((vehicleScale?.length ?? 0) < 20) {
      console.warn("vehicleScale should contain at least 20 levels");
      return;
    }

    livemapRef.current?.modules.vehicle.configure({
      vehicleScale: vehicleScale,
    });
  }, [vehicleScale]);

  useEffect(() => {
    if ((vehicleBadgeScale?.length ?? 0) < 20) {
      console.warn("vehicleBadgeScale should contain at least 20 levels");
      return;
    }

    livemapRef.current?.modules.vehicleBadge.configure({
      scale: vehicleBadgeScale,
    });
  }, [vehicleBadgeScale]);

  // This component attaches to maplibre,
  // and does not render anything.
  return null;
}
