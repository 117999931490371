import { GeographicMapFeatures } from "./GeographicMapFeatures";
import { GeographicMapLegend } from "./GeographicMapLegend";
import { ImageOnMap } from "./ImageOnMap";
import { TransitMapLegend } from "./TransitMapLegend";
import { TransitMapRoutes } from "./TransitMapRoutes";
import { TransitMapStopLabels } from "./TransitMapStopLabels";
import { TransitMapStops } from "./TransitMapStops";

interface LocalTransitMapProps {
  minZoomLevel: number;
}

export function LocalTransitMap({ minZoomLevel }: LocalTransitMapProps) {
  return (
    <>
      <ImageOnMap
        id="logo-lkpg"
        url="images/ostgotatrafiken-logo.png"
        coordinates={[
          [15.69, 58.44],
          [15.73, 58.44],
          [15.73, 58.436818692],
          [15.69, 58.436818692],
        ]}
        minZoom={minZoomLevel + 1}
      ></ImageOnMap>
      <ImageOnMap
        id="header-lkpg"
        url="images/header-lkpg.png"
        coordinates={[
          [15.497, 58.44],
          [15.537, 58.44],
          [15.537, 58.42852],
          [15.497, 58.42852],
        ]}
        minZoom={minZoomLevel + 1}
      ></ImageOnMap>
      <ImageOnMap
        id="linjer-lkpg"
        url="images/linjer.png"
        coordinates={[
          [15.497, 58.4047],
          [15.5052, 58.4047],
          [15.5052, 58.403],
          [15.497, 58.403],
        ]}
        minZoom={minZoomLevel + 1}
      ></ImageOnMap>
      <ImageOnMap
        id="bytespunkter-lkpg"
        url="images/bytespunkter.png"
        coordinates={[
          [15.5239, 58.3763],
          [15.543, 58.3763],
          [15.543, 58.3746],
          [15.5239, 58.3746],
        ]}
        minZoom={minZoomLevel + 1}
      ></ImageOnMap>
      <ImageOnMap
        id="logo-nkpg"
        url="images/ostgotatrafiken-logo.png"
        coordinates={[
          [16.2367, 58.6685],
          [16.2742, 58.6685],
          [16.2742, 58.6655],
          [16.2367, 58.6655],
        ]}
        minZoom={minZoomLevel + 1}
      ></ImageOnMap>
      <ImageOnMap
        id="header-nkpg"
        url="images/header-nkpg.png"
        coordinates={[
          [16.11, 58.669],
          [16.22, 58.669],
          [16.22, 58.66],
          [16.11, 58.66],
        ]}
        minZoom={minZoomLevel + 1}
      ></ImageOnMap>
      <ImageOnMap
        id="linjer-nkpg"
        url="images/linjer.png"
        coordinates={[
          [16.261, 58.6555],
          [16.2742, 58.6555],
          [16.2742, 58.653],
          [16.261, 58.653],
        ]}
        minZoom={minZoomLevel + 1}
      ></ImageOnMap>
      <ImageOnMap
        id="bytespunkter-nkpg"
        url="images/bytespunkter.png"
        coordinates={[
          [16.244, 58.6255],
          [16.2742, 58.6255],
          [16.2742, 58.623],
          [16.244, 58.623],
        ]}
        minZoom={minZoomLevel + 1}
      ></ImageOnMap>
      <GeographicMapFeatures minZoom={minZoomLevel}></GeographicMapFeatures>
      <TransitMapRoutes minZoom={minZoomLevel}></TransitMapRoutes>
      <TransitMapStops minZoom={minZoomLevel}></TransitMapStops>
      <TransitMapStopLabels minZoom={minZoomLevel}></TransitMapStopLabels>
      <TransitMapLegend minZoom={minZoomLevel}></TransitMapLegend>
      <GeographicMapLegend minZoom={minZoomLevel}></GeographicMapLegend>
    </>
  );
}
