import * as Sentry from "@sentry/react";
import { Core } from "livemap-gl";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import WebFont from "webfontloader";

import { TransitMap, Error } from "./components";
import { Legend } from "./components/Legend";

import { MaplibreProvider, LivemapProvider, RoutesProvider } from "./contexts/";

type AppMode = "fullscreen" | "app";

function App() {
  const [map, setMap] = useState<maplibregl.Map | null>(null);
  const [livemap, setLivemap] = useState<Core | null>(null);
  const onMapReady = useCallback((map: maplibregl.Map) => setMap(map), []);
  const [appMode] = useState<AppMode>("fullscreen");

  useEffect(() => {
    WebFont.load({
      custom: {
        families: ["HelveticaNeueLTStd:n7,n4", "BitterPro:n7"],
      },
    });
  }, []);

  return (
    <Sentry.ErrorBoundary
      fallback={Error}
      beforeCapture={(scope) => scope.setTag("element", "MapContainer")}
    >
      <MaplibreProvider maplibre={map}>
        <LivemapProvider livemap={livemap}>
          <RoutesProvider>
            <MapContainer>
              <TransitMap
                onMapReady={onMapReady}
                onLivemapReady={setLivemap}
              ></TransitMap>
            </MapContainer>
            {appMode === "app" && (
              <LegendContainer>
                <Legend></Legend>
              </LegendContainer>
            )}
          </RoutesProvider>
        </LivemapProvider>
      </MaplibreProvider>
    </Sentry.ErrorBoundary>
  );
}

const MapContainer = styled.div`
  grid-area: root;
  z-index: 0; // We need to set z-index because maplibre gets on top even if it is placed first in TSX.
`;

const LegendContainer = styled.div`
  place-self: end start;
  z-index: 1;

  @media screen and (max-width: 220px) {
    transform-origin: left bottom;
    scale: 0.9;
    margin-left: 0;
    padding-left: 0;
  }
`;

export default App;
