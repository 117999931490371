import React, { createContext, PropsWithChildren } from "react";
import { Map } from "maplibre-gl";

export const MaplibreContext = createContext<Map | null>(null);

export interface MaplibreProviderProps {
  maplibre: Map | null;
}

type Props = PropsWithChildren<MaplibreProviderProps>;

export function MaplibreProvider({ maplibre, children }: Props) {
  return (
    <MaplibreContext.Provider value={maplibre}>
      {children}
    </MaplibreContext.Provider>
  );
}
