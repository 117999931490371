import { MapMouseEvent } from "maplibre-gl";
import { useEffect } from "react";
import { fitFeatureBounds, getBoundsPadding } from "../core/fit-bounds";
import { useMap } from "../hooks/map";
import { useRoutes } from "../hooks/routes";

export function TransitMapLegend({ minZoom = 0 }) {
  const map = useMap();
  const routes = useRoutes();

  useEffect(() => {
    if (!map) return;
    map.addSource("transit-map-legend", {
      type: "geojson",
      data: null,
    });

    import("../data/transit-map-legend.json").then((data) => {
      (map as any)?.getSource("transit-map-legend")?.setData(data);
    });

    map.addSource("transit-map-legend-labels", {
      type: "geojson",
      data: null,
    });

    import("../data/transit-map-legend-labels.json").then((data) => {
      (map as any)?.getSource("transit-map-legend-labels")?.setData(data);
    });

    (map as any).addLayer({
      id: "transit-map-legend-lines",
      type: "line",
      source: "transit-map-legend",
      minzoom: minZoom,
      layout: {
        "line-join": "bevel",
        "line-cap": "butt",
      },
      paint: {
        "line-color": ["get", "stroke"],
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          1,
          1,
          17,
          ["*", 35, ["get", "stroke-width"]],
        ],
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom,
          0,
          minZoom + 1,
          1,
        ],
      },
      filter: ["==", "$type", "LineString"],
    });

    map.addLayer({
      id: "transit-map-legend-polygons",
      type: "fill",
      source: "transit-map-legend",
      minzoom: minZoom,
      layout: {},
      paint: {
        "fill-color": ["get", "fill"],
        "fill-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom,
          0,
          minZoom + 1,
          1,
        ],
      },
      filter: ["==", "$type", "Polygon"],
    });

    map.addLayer({
      id: "transit-map-legend-polygons-stroke",
      type: "line",
      source: "transit-map-legend",
      minzoom: minZoom,
      layout: {},
      paint: {
        "line-color": ["get", "stroke"],
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          11,
          2,
          17,
          56,
        ],
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom,
          0,
          minZoom + 1,
          1,
        ],
      },
      filter: ["==", "$type", "Polygon"],
    });

    (map as any).addLayer({
      id: "transit-map-legend-polygon-labels",
      type: "symbol",
      source: "transit-map-legend-labels",
      minzoom: minZoom,
      layout: {
        "text-field": ["get", "route_short_name"],
        "text-font": ["BitterPro-Bold"],
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          8,
          ["*", 1, ["get", "text-scale"]],
          16,
          ["*", 140, ["get", "text-scale"]],
        ],
        "text-overlap": "always",
        "text-offset": [0, -0.3],
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
      },
      paint: {
        "text-color": ["coalesce", ["get", "text-color"], "white"],
        "text-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom,
          0,
          minZoom + 1,
          1,
        ],
      },
      filter: [">", "text-scale", 1],
    });

    (map as any).addLayer({
      id: "transit-map-legend-labels",
      type: "symbol",
      source: "transit-map-legend-labels",
      minzoom: minZoom,
      layout: {
        "text-field": ["get", "text"],
        "text-font": ["HelveticaNeueLTStd-Cn"],
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          8,
          ["*", 1, ["get", "text-scale"]],
          16,
          ["*", 140, ["get", "text-scale"]],
        ],
        "text-line-height": 1,
        "text-anchor": ["get", "text-anchor"],
        "text-overlap": "always",
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-max-width": Infinity,
      },
      paint: {
        "text-color": "white",
        "text-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom,
          0,
          minZoom + 1,
          1,
        ],
      },
      filter: ["==", "text-scale", 1],
    });

    return () => {
      map?.removeLayer("transit-map-legend-lines");
      map?.removeLayer("transit-map-legend-polygons");
      map?.removeLayer("transit-map-legend-polygons-stroke");
      map?.removeLayer("transit-map-legend-polygon-labels");
      map?.removeLayer("transit-map-legend-labels");
      map?.removeSource("transit-map-legend");
      map?.removeSource("transit-map-legend-labels");
    };
  }, [map, minZoom]);

  useEffect(() => {
    if (!map) return;
    if (!routes) return;

    const onLegendClick = (
      ev: MapMouseEvent & {
        features?: GeoJSON.Feature[];
      }
    ) => {
      if (ev.features?.length !== 1 || !map) return;

      const routeShortName = ev.features[0].properties?.route_short_name;
      const region = ev.features[0].properties?.region;

      const matchingRoutes = routes?.filter(
        (r) =>
          (r.properties.regions as string[]).includes(region) &&
          r.properties.route_short_name === routeShortName
      );

      if (matchingRoutes?.length !== 1) return;

      fitFeatureBounds(matchingRoutes[0], map, getBoundsPadding(map));
    };
    map.on("click", "transit-map-legend-polygons", onLegendClick);
    map.on("click", "transit-map-legend-labels", onLegendClick);

    return () => {
      map.off("click", "transit-map-legend-polygons", onLegendClick);
      map.off("click", "transit-map-legend-labels", onLegendClick);
    };
  }, [map, routes]);
  return null;
}
