import { useEffect } from "react";
import { useMap } from "../hooks/map";

export function GeographicMapFeatures({ minZoom = 0 }) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    map.addSource("geography", {
      type: "geojson",
      data: null,
    });

    import("../data/geographic-features.json").then((geography) => {
      (map as any)?.getSource("geography").setData(geography);
    });

    (map as any).addLayer({
      id: "geography-lines",
      type: "line",
      source: "geography",
      minzoom: minZoom - 1,
      layout: {
        "line-join": "bevel",
        "line-cap": "square",
      },
      paint: {
        "line-color": ["get", "stroke"],
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          1,
          1,
          17,
          ["*", 35, ["get", "stroke-width"]],
        ],
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          minZoom - 1,
          0,
          minZoom,
          1,
        ],
      },
      filter: ["==", "$type", "LineString"],
    });

    return () => {
      map?.removeLayer("geography-lines");
      map?.removeSource("geography");
    };
  }, [map, minZoom]);
  return null;
}
