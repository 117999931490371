import {
  Map,
  LngLatBounds,
  PaddingOptions,
  RequireAtLeastOne,
} from "maplibre-gl";

export const SMALL_SCREEN_WIDTH = 768;

export function getBoundsPadding(map: Map): PaddingOptions {
  const canvas = map.getCanvas();
  const paddingRatio = canvas.width < SMALL_SCREEN_WIDTH ? 0.01 : 0.1;
  const pixelRatio = map.getPixelRatio();
  const horizPadding = (canvas.width * paddingRatio) / pixelRatio;
  const vertPadding = (canvas.height * paddingRatio) / pixelRatio;

  return {
    bottom: vertPadding,
    left:
      horizPadding + canvas.width / pixelRatio < SMALL_SCREEN_WIDTH ? 0 : 270,
    right: horizPadding,
    top: vertPadding,
  };
}

export function fitFeatureBounds(
  feature: any,
  map: Map,
  padding?: number | RequireAtLeastOne<PaddingOptions>
) {
  const bounds = feature.geometry.coordinates.reduce(
    (a: LngLatBounds, coordinate: [number, number]) => a.extend(coordinate),
    new LngLatBounds()
  );

  map.fitBounds(bounds, {
    padding: padding,

    maxDuration: 1500,
  });
}
