import { GeoJSONFeature } from "maplibre-gl";
import { createContext, PropsWithChildren, useEffect, useState } from "react";

export const RoutesContext = createContext<GeoJSONFeature[] | null>(null);

export function RoutesProvider({ children }: PropsWithChildren) {
  const [routes, setRoutes] = useState<GeoJSONFeature[] | null>(null);

  useEffect(() => {
    import("../data/transit-map-routes.json").then((routes) => {
      setRoutes(routes.features as any);
    });
  }, []);

  return (
    <RoutesContext.Provider value={routes}>{children}</RoutesContext.Provider>
  );
}
