import { RegionalTransitMapRoutes } from "./RegionalTransitMapRoutes";
import { RegionalTransitMapStopLabels } from "./RegionalTransitMapStopLabels";
import { RegionalTransitMapStops } from "./RegionalTransitMapStops";

interface RegionalTransitMapProps {
  maxZoomLevel: number;
}

export function RegionalTransitMap({ maxZoomLevel }: RegionalTransitMapProps) {
  return (
    <>
      <RegionalTransitMapRoutes
        maxZoom={maxZoomLevel}
      ></RegionalTransitMapRoutes>
      <RegionalTransitMapStops maxZoom={maxZoomLevel}></RegionalTransitMapStops>
      <RegionalTransitMapStopLabels
        maxZoom={maxZoomLevel}
      ></RegionalTransitMapStopLabels>
    </>
  );
}
