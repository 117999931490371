import { createContext, PropsWithChildren } from "react";
import { Core } from "livemap-gl";

export const LivemapContext = createContext<Core | null>(null);

export interface LivemapProviderProps {
  livemap: Core | null;
}

type Props = PropsWithChildren<LivemapProviderProps>;

export function LivemapProvider({ livemap, children }: Props) {
  return (
    <LivemapContext.Provider value={livemap}>
      {children}
    </LivemapContext.Provider>
  );
}
